<template>
	<div v-loading.lock="fullscreenLoading">
		<div class="gg-container">
			<header class="header">
				<h3>订单详情</h3>
			</header>
			<!--订单基本信息-->
			<div v-if="info.timeline.length > 0">
				<el-steps :active="currenTime" align-center>
					<el-step v-for="(item, index) in info.timeline" :key="index" :title="item.content"
						:description="item.timestamp"></el-step>
				</el-steps>
			</div>
			<!--进度-->

			<!-- 当前状态-->
			<div class="orderStatusBox bbox xbetween">
				<div class="" style="padding: 0 20px">
					<span class="text-danger">
						当前订单状态：{{ info.order_status_name }}
					</span>
				</div>

				<div class="orderBtnBox bbox xbetween">
					<!--          // 订单状态(0=已取消1=待支付,2=待发货,3=待收货,4=售后中,5已完成）-->
					<el-button type="primary" size="mini" v-if="
              info.order_status == 1 ||
              info.order_status == 2 ||
              (info.order_status == 0 && info.order_type == 2)
            " @click="editSHR">修改收货人信息
					</el-button>
					<!--          普通订单，代付款 代发货的订单可以取消  -->
					<el-button type="danger" size="mini" v-if="
              info.order_type == 1 &&
              (info.order_status == 1 || info.order_status == 2)
            " @click="cancelOrder">取消订单
					</el-button>

					<el-button type="danger" size="mini" v-if="info.order_status == 0 && info.order_type == 1"
						@click="delOrder">删除订单
					</el-button>

					<el-button type="success" size="mini" v-if="info.order_status == 3 || info.order_status == 5"
						@click="viewExpress">物流详情
					</el-button>
				</div>
			</div>
		</div>
		<!--    -->

		<div class="gg-container">
			<h3>基本信息</h3>
			<div class="tableWrap">
				<table class="orderTable">
					<thead>
						<tr>
							<th>订单编号</th>
							<th>订单状态</th>
							<th>外部订单编号</th>
							<th>用户ID</th>
							<th>用户信息</th>
							<th>订单类型</th>
							<th>同步订单状态</th>
							<th>订单备注</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text-center">
							<td>{{ info.order_sn }}</td>
							<td>{{ info.order_status_name }}</td>
							<td>{{ info.external_code }}</td>
							<td>{{ info.member.member_id }}</td>
							<td>{{ info.member.realname }}{{ info.member.mobile }}</td>
							<td>{{ info.order_type_name }}</td>
							<td>{{ info.sync_status_name }}</td>
							<td>{{ info.order_comment }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!--收货人信息-->
		<div class="gg-container">
			<h3>收货人信息</h3>
			<div class="tableWrap">
				<table class="orderTable">
					<thead>
						<tr>
							<th>收货人</th>
							<th>手机号码</th>
							<th>邮政编码</th>
							<th>收货地址</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text-center">
							<td>{{ info.shr_name }}</td>
							<td>{{ info.shr_mobile }}</td>
							<td>{{ info.zip_code }}</td>
							<td>{{ info.shr_address }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- 商品信息 -->
		<div class="gg-container">
			<h3>商品信息</h3>
			<el-table v-loading="listLoading" :data="info.goods" border show-summary :summary-method="getSummaries"
				style="width: 100%">
				<el-table-column prop="img_url" label="药品图片" align="center" width="150">
					<template slot-scope="{ row }">
						<div class="imgBox">
							<el-image style="width: 88px; height: 67px" :src="row.img_url"
								:preview-src-list="[row.img_url]">
							</el-image>
							<div class="isPres" v-if="row.pres_not == 1">处方药</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="goods_name" label="商品名称"> </el-table-column>

				<el-table-column prop="item_code" label="商品代码"> </el-table-column>

				<el-table-column prop="warehouse_code" label="仓库号">
				</el-table-column>

				<el-table-column prop="spec" label="商品规格" align="center">
				</el-table-column>

				<el-table-column prop="goods_price" label="价格" align="center">
				</el-table-column>

				<el-table-column prop="number" label="数量" align="center">
				</el-table-column>
				<el-table-column prop="amount" label="小计" align="center">
				</el-table-column>
			</el-table>
		</div>
		<!-- 金额信息 -->
		<div class="gg-container">
			<h3>金额信息</h3>
			<div class="tableWrap">
				<table class="orderTable">
					<thead>
						<tr>
							<th>商品金额</th>
							<th>运费</th>
							<th>优惠金额</th>
							<th>订单总金额</th>
							<th>实付金额</th>
							<th v-if="info.order_type == 2">预付金额</th>
							<th v-if="info.order_type == 2">结余金额</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text-center">
							<td>￥{{ info.goods_amount }}</td>
							<td>￥{{ info.express_fee }}</td>
							<td>-￥{{ info.discount_amount }}</td>
							<td class="text-danger">￥{{ info.total_amount }}</td>
							<td class="text-danger">￥{{ info.actual_amount }}</td>
							<td class="text-primary" v-if="info.order_type == 2">
								￥{{ info.earn_amount }}
							</td>
							<td class="text-danger" v-if="info.order_type == 2">
								￥{{ info.cash_amount }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<!-- 管理信息 -->
		<div class="gg-container" v-if="info.manage">
			<h3>管理信息</h3>
			<div class="tableWrap">
				<table class="orderTable">
					<thead>
						<tr>
							<th>档案编号</th>
							<th>微信原始id</th>
							<th>诊疗编号</th>
							<th>预付类型</th>
							<th>是否开方</th>
							<th>外部会员编号</th>
							<th>商户编号</th>
							<th>工号</th>
							<th>运营机微信号</th>
							<th>备注</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text-center">
							<td>{{ info.manage.contract_id }}</td>
							<td>{{ info.manage.wxid }}</td>
							<td>{{ info.manage.diag_id }}</td>
							<td v-if="info.manage.earn_status==1">小程序</td>
							<td v-else>微信已收</td>
							<td v-if="info.manage.pres_status == 1">是</td>
							<td v-else>否</td>
							<td>
								{{ info.manage.out_user_id }}
							</td>
							<td>
								{{ info.manage.merchant_id }}
							</td>
							<td>{{ info.manage.ad_expand }}</td>
							<td>{{ info.manage.opera_wechat }}</td>
							<td v-if="info.manage.remark">{{ info.manage.remark }}</td>
							<td v-else></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<!-- 操作信息 -->
		<div class="gg-container">
			<h3>操作信息</h3>
			<div class="tableWrap">
				<table class="orderTable">
					<thead>
						<tr>
							<th>操作者</th>
							<th>操作时间</th>
							<th>操作事项</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text-center" v-for="(td, index) in info.logs" :key="index">
							<td>{{ td.username }}</td>
							<td>{{ td.operated_at }}</td>
							<td>{{ td.comment }}</td>
						</tr>
						<tr v-if="info.logs.length == 0">
							<td colspan="3" align="center">当前无操作信息</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<!--    修改收货人信息   -->
		<el-dialog title="收货人信息" :visible.sync="dialogSHRVisible" width="590px" v-if="dialogSHRVisible">
			<el-form ref="ruleForm" :model="currInfo" label-width="100px" :rules="rules" class="demo-ruleForm">
				<el-form-item label="收货人姓名" prop="shr_name">
					<el-input type="text" v-model="currInfo.shr_name" placeholder="请输入收货人姓名" autofocus="true">
					</el-input>
				</el-form-item>

				<el-form-item label="收货人手机" prop="shr_mobile">
					<el-input type="text" v-model="currInfo.shr_mobile" placeholder="请输入收货人手机" maxlength="11">
					</el-input>
				</el-form-item>

				<el-form-item label="收货人地址" prop="district_code">
					<el-cascader style="width: 450px" :options="pcaOptions" ref="cascaderAddr"
						v-model="currInfo.district_code" @change="handleChange">
					</el-cascader>
				</el-form-item>
				<el-form-item label="" prop="shr_address">
					<el-input type="text" v-model="currInfo.shr_address" placeholder="请输入收货人地址"></el-input>
				</el-form-item>
				<el-form-item label="收货人邮编" prop="sort">
					<el-input type="number" v-model="currInfo.zip_code" placeholder="请输入收货人邮编" maxlength="10">
					</el-input>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button type="success" size="small" @click="saveForm('ruleForm')">保存</el-button>
			</span>
		</el-dialog>
		<!--    修改收货人信息   -->

		<!--    物流信息  -->
		<el-dialog title="物流信息" :visible.sync="dialogExpressVisible" width="650px" v-if="dialogExpressVisible">
			<div style="overflow: auto">
				<div class="" style="margin-top:15px;font-size: 15px;margin-bottom: 15px;">
					<el-row>
						<el-col :span="12">
							快递公司: {{logisticsInfo[0].express_company}}
						</el-col>
						<el-col :span="12">
							快递单号: {{logisticsInfo[0].express_no}}
						</el-col>
					</el-row>
				</div>
				<el-carousel arrow="never" :autoplay="false" trigger="click">
					<el-carousel-item v-for="(item, index1) in logisticsInfo" :key="index1">
						<div v-if="item.express_logs.length">
							<el-timeline-item v-for="(activity, index) in item.express_logs" :key="index"
								:timestamp="activity.time">
								{{ activity.status }}
							</el-timeline-item>
						</div>
						<div v-else style="text-align: center;margin-top: 120px;">
							暂无物流信息
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</el-dialog>
		<!--    物流信息  -->
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex"; // secondary package based on el-pagination
	import {
		pharmacyOrderDetail,
		delOrderDetail,
		cancelOrderDetail,
		editOrderSHR,
		getPostcode,
		logisticsDetail
	} from "@/api/pharmacy/pharmacy";
	import {
		regionData
	} from "element-china-area-data";
	import {
		getDrugOrderdetail,
		changeOrderSHR,
    putAdminOrderRefund
	} from "@/api/order";

	export default {
		name: "orderDetail",
		components: {},
		computed: {
			...mapState({}),
		},
		mounted() {},
		created() {
			this.initPage();
		},
		data() {
			return {
				currenTime: 3,
				info: {
					timeline: [],
					logs: [],
					member: {},
				},
				id: "",
				currInfo: {},
				fullscreenLoading: false,

				dialogSHRVisible: false,
				dialogExpressVisible: false,
				logisticsInfo:[],//物流信息
				pcaOptions: regionData,
				ruleForm: {},
				rules: {
					shr_name: [{
						required: true,
						message: "请输入收货人姓名",
						trigger: "blur"
					}, ],
					shr_mobile: [{
							required: true,
							message: "请输入收货人手机号码",
							trigger: "blur"
						},
						{
							required: true,
							message: "请输入收货人手机号码",
							trigger: "blur"
						},
					],
					shr_address: [{
						required: true,
						message: "请输入收货地址",
						trigger: "blur"
					}, ],
					zip_code: [{
						required: true,
						message: "请输入邮政编码",
						trigger: "blur"
					}, ],
				},

				expressList: [],
				total: 0,
				listLoading: false,
				listQuery: {
					page: 1,
					limit: 5,
					importance: undefined,
					title: undefined,
					type: undefined,
					sort: "+id",
				},
			};
		},
		methods: {
			initPage() {
				this.id = this.$route.query.id;
				this.getInfo();
			},
			getInfo() {
				this.listLoading = true;
				this.fullscreenLoading = true;
				let params = {
					order_common_id: this.id
				};

				getDrugOrderdetail(params)
					.then((response) => {
						let data = response;
						if (data.code == 200) {
							let _data = data.data;
							this.info = _data;
							this.expressList = _data.express;
						}
						for (let i = 0; i < this.info.timeline.length; i++) {
							if (this.info.timeline[i].timestamp) {
								this.currenTime = i + 1;
							}
						}

						this.fullscreenLoading = false;
						this.listLoading = false;
					})
					.catch((error) => {
						this.listLoading = false;
						this.fullscreenLoading = false;
					});
			},
			//物流详情
			logisticsDetail() {
				logisticsDetail(this.info.order_common_id).then(res => {
					this.logisticsInfo = res.data
				})
			},
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = "总价";
						return;
					}
					if (column.property == "amount") {
						const values = data.map((item) => Number(item[column.property]));
						if (!values.every((value) => isNaN(value))) {
							sums[index] = values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0);
							sums[index] += " 元";
						} else {
							sums[index] = "N/A";
						}
					}
				});

				return sums;
			},
			editSHR() {
				this.dialogSHRVisible = true;
				this.currInfo = {
					...this.info
				};
				this.currInfo.district_code = [
					this.info.province+'',
					this.info.city+'',
					this.info.area+'',
				];
				console.log('this.currInfo.district_code',this.currInfo.district_code)
			},
			saveForm(form) {
				this.$refs[form].validate((valid) => {
					if (!valid) {
						return;
					} else {
						let params = {};
						params.order_common_id = this.currInfo.order_common_id;
						params.shr_name = this.currInfo.shr_name;
						params.shr_mobile = this.currInfo.shr_mobile;
						params.zip_code = this.currInfo.zip_code;

						params.province = this.currInfo.province;
						params.city = this.currInfo.city;
						params.area = this.currInfo.area;
						params.shr_address = this.currInfo.shr_address;
						changeOrderSHR(params)
							.then((response) => {
								let data = response;
								if (data.code == 200) {
									this.$message({
										type: "success",
										message: "修改成功",
									});
									this.getInfo();
									this.dialogSHRVisible = false;
								} else {
									this.$message({
										type: "error",
										message: data.msg,
									});
								}
							})
							.catch((error) => {
								console.log(error);
							});
					}
				});
			},
			cancelOrder() {
        console.log(this.$route.query.id)
        console.log(111111111111)
        var data = {
          order: this.$route.query.id
        }
				this.$confirm("是否确定取消此订单？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error",
					})
					.then(() => {
						// let params = {
						// 	pharmacy_order_id: this.info.pharmacy_order_id,
						// };
            putAdminOrderRefund(data)
							.then((response) => {
								let data = response;
								if (data.code == 200) {
									this.$message({
										type: "success",
										message: "订单取消成功!",
									});
									this.getInfo();
								} else {
									this.$message({
										type: "error",
										message: data.msg + "," + data.code,
									});
								}
							})
							.catch((error) => {
								console.log(error);
							});
					})
					.catch(() => {});
			},

			delOrder() {
        console.log(this.$route.query.id)
        console.log(111111111111)
        var data = {
          order: this.$route.query.id
        }
				this.$confirm("是否确定删除此订单？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error",
					})
					.then(() => {
						// let params = {
						// 	pharmacy_order_id: this.info.pharmacy_order_id,
						// };
            putAdminOrderRefund(data)
							.then((response) => {
								let data = response;
								if (data.code == 200) {
									this.$message({
										type: "success",
										message: "订单删除成功!",
									});
								} else {
									this.$message({
										type: "error",
										message: data.msg + "," + data.code,
									});
								}
							})
							.catch((error) => {
								console.log(error);
							});
					})
					.catch(() => {});
			},
			viewExpress() {
				// if (this.logisticsInfo.length < 1) {
				// 	alert("暂无物流信息");
				// 	return;
				// }
				this.logisticsDetail()
				this.dialogExpressVisible = true;
			},
			handleChange(value) {
				this.currInfo.area = value[2];
				this.currInfo.city = value[1];
				this.currInfo.province = value[0];
				let nodes = this.$refs["cascaderAddr"].getCheckedNodes();
				let address = nodes[0].pathLabels.join("");
				console.log(address);
				getPostcode(address);
			},
		},
		watch: {
			// eslint-disable-next-line no-unused-vars
			$route(newV, oldV) {
			  this.initPage();
			},
		},
	};
</script>
<style lang="scss">
	ul,
	li {
		list-style: none;
	}

	.el-carousel__button {
		background-color: #2d3a4b;
	}
</style>
<style scoped lang="scss">
	.orderStatusBox {
		padding: 30px 10px;
		background-color: #e6f6fb;
		margin-top: 30px;
		border-radius: 5px;
	}

	h3 {
		margin-top: 0;
	}

	.orderTable {
		width: 100%;
		border-collapse: collapse;
		font-size: 14px;

		thead {
			th {
				background-color: #2f4256;
				border-right-color: #2f4256;
				color: #fff;
				padding: 10px 0;
				font-weight: bold;
			}
		}

		tbody {
			.seat {
				background-color: #fff;

				td {
					border: none;
					height: 20px;
				}
			}

			.tb-head {
				background-color: #f0f0f0;
			}

			td {
				border: 1px solid #dfe6ec;
				padding: 10px 5px;
			}

			.text-center {
				td {
					text-align: center;
				}
			}
		}
	}

	.imgBox {
		position: relative;
		text-align: center;
		width: 88px;
		height: 67px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);

		.isPres {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 48px;
			height: 30px;
			text-align: right;
			color: #fff;
			background: url("../../assets/isPres.png") no-repeat;
			background-size: contain;
			padding-top: 10px;
		}
	}

	.orderBtnBox {
		button {
			margin-left: 10px;
		}
	}
</style>

<style>
	.el-carousel__item {
		overflow-y: auto;
	}
</style>
