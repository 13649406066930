import request from '@/utils/request'
import {getToken} from "@/utils/auth";
//药房列表
export function getPharmacyList(query) {
    return request({
        url: '/admin/drug-pharmacies',
        method: 'get',
        params: query
    })
}

//药房编辑
export function editPharmacy(query) {
    return request({
        url: '/admin/drug-pharmacies/'+query.pharmacy_id,
        method: 'put',
        params: query
    })
}

//药房新增
export function addPharmacy(query) {
    return request({
        url: '/admin/drug-pharmacies',
        method: 'post',
        data: query
    })
}

//药房详情
export function pharmacyDetail(query) {
    return request({
        url: 'admin/drug-pharmacies/'+query.pharmacy_id,
        method: 'get',
        data: query
    })
}

//药房禁用启用  /admin/drug-pharmacies/:pharmacy_id/status
export function setDrugPharmacyStatus(data) {
    return request({
        url: '/admin/drug-pharmacies/'+data.pharmacy_id +'/status',
        method: 'put',
        data:data
    })
}

//药房药品列表   /admin/drug-pharmacies/:id/drugs
export function pharmacyDrugList(query) {
    return request({
        url: '/admin/drug-pharmacies/'+query.drug_pharmacy_id+'/drugs',
        method: 'get',
        params: query
    })
}
export function myPharmacyDrugList(query) {
    return request({
        url: '/admin/drug-pharmacies/drugList',
        method: 'get',
        params: query
    })
}



//药房新增药品   /admin/drug-pharmacies/:pharmacy_id/drugs
export function addDrugPharmacy(data) {
    return request({
        url: '/admin/drug-pharmacies/'+data.pharmacy_id+'/drugs',
        method: 'post',
        data:data
    })
}

// 药房 药品编辑   /admin/pharmacy-drugs/:id
export function editDrugPharmacy(data) {
    return request({
        url: '/admin/pharmacy-drugs/'+data.pharmacy_drug_id,
        method: 'put',
        data:data
    })
}
// 药房 药品编辑   /admin/pharmacy-drugs/:id
export function editDrugPharmacyNew(id,params) {
    return request({
        url: `admin/pharmacy-drugs/${id}/status`,
        method: 'put',
        params
    })
}


export function editPharDrug(id,data) {
    return request({
        url: `/admin/pharmacy-drugs/${id}/editPharDrug`,
        method: 'put',
        data
    })
}


export function editStock(id,data) {
    return request({
        url: `/admin/pharmacy-drugs/${id}/editStock`,
        method: 'put',
        data
    })
}


//药房订单  /admin/pharmacy-orders
export function pharmacyOrderList(query) {
    return request({
        url: '/admin/pharmacy-orders',
        method: 'get',
        params: query
    })
}

// 订单详情  /admin/pharmacy-orders/:id
export function pharmacyOrderDetail(query) {
    return request({
        url: '/admin/pharmacy-orders/'+query.pharmacy_order_id,
        method: 'get',
        params: query
    })
}

//修改收货人信息  /admin/pharmacy-orders/:id
export function editOrderSHR(data) {
    return request({
        url: '/admin/pharmacy-orders/'+data.pharmacy_order_id+'/contact',
        method: 'put',
        data: data
    })
}

//修改收货人信息  /admin/pharmacy-orders/:id
export function editOrderDetail(data) {
    return request({
        url: '/admin/pharmacy-orders/'+data.id,
        method: 'put',
        params: data
    })
}

//添加备注信息
export function setRemark(data) {
    return request({
        url: '/admin/pharmacy-orders/'+data.pharmacy_order_id+'/comment',
        method: 'put',
        params: data
    })
}

//取消订单  /admin/pharmacy-orders/:id/cancel
export function cancelOrderDetail(data) {
    return request({
        url: '/admin/pharmacy-orders/'+data.pharmacy_order_id+'/cancel',
        method: 'put',
        data: data
    })
}

//删除订单  /admin/pharmacy-orders/:id
export function delOrderDetail(data) {
    return request({
        url: '/admin/pharmacy-orders/'+data.pharmacy_order_id,
        method: 'delete',
        params: data
    })
}

//物流详情  /admin/express-query
export function queryExpress(query) {
    return request({
        url: '/admin/express-query',
        method: 'get',
        params: query
    })
}

//药房发货  /admin/pharmacy-orders/:id/send
export function sendOrderExpress(data) {
    return request({
        url: '/admin/pharmacy-orders/'+data.pharmacy_order_id+'/send',
        method: 'put',
        data: data
    })
}


// 当前药房信息  /admin/drug-pharmacies-current
export function myPharmacies(query) {
    return request({
        url: '/admin/drug-pharmacies-current',
        method: 'get',
        params: query
    })
}


//药房信息概览
export function phDashboard(query) {
    return request({
        url: '/admin/drug-pharmacy/home',
        method: 'get',
        params: query
    })
}

//药房信息概览
export function phDashboardChart(query) {
    return request({
        url: '/admin/drug-pharmacy/home-chart',
        method: 'get',
        params: query
    })
}



//药房信息概览
export function myPharmaciesIntro(query) {
    return request({
        url: '/admin/pharmacy-orders-summary',
        method: 'get',
        params: query
    })
}

//订单导出   /admin/pharmacy-orders-export
export function exportOrders(query) {
    return request({
        headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',
        url: '/admin/pharmacy-orders-export',
        method: 'get',
        params: query
    })
}


//批量导入  /admin/pharmacy-orders-batch-send
export function exportFiles(data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : 'Bearer '+ getToken()
        },
        url: '/admin/pharmacy-orders-batch-send',
        method: 'post',
        data: data
    })
}


export function getPostcode(data) {
    return request({
        url: 'http://cpdc.chinapost.com.cn/web/index.php?m=postsearch&c=index&a=ajax_addr&searchkey='+data,
        method: 'get',
        params: data
    })
}


//获取订单审核列表 
export function examineList(query) {
    return request({
        url: '/admin/pharmacy-orders/examineList',
        method: 'get',
        params: query
    })
}

//订单审核和批量审核
export function updataExamine(data) {
    return request({
        url: '/admin/pharmacy-orders/examine',
        method: 'put',
        data: data
    })
}



//订单审核批量导出
export function examineExportList(query) {
    return request({
        headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',
        url: '/admin/pharmacy-orders/examineExport',
        method: 'get',
        params: query
    })
}


//物流详情 
export function logisticsDetail(id) {
    return request({
        url: `admin/order/expressInfo/${id}`,
        method: 'get'
    })
}